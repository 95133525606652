(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/brand-mapping/assets/javascripts/compile-brand.js') >= 0) return;  svs.modules.push('/components/lb-utils/brand-mapping/assets/javascripts/compile-brand.js');
"use strict";

const _excluded = ["brand"],
  _excluded2 = ["brand"];
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], -1 === t.indexOf(o) && {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (-1 !== e.indexOf(n)) continue; t[n] = r[n]; } return t; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
let baseBrandModel;
let product;
let subProduct;
let utilsProducts;
let tipsenShared;
if (svs.isServer) {
  utilsProducts = require('trinidad-core').core.components.require('lb-utils', 'sport-products').api;
  tipsenShared = require('../../../../sport/tipsen-shared/assets/javascripts/tipsen-shared.es6');
  baseBrandModel = require('./brands/base-brand.es6');
  product = require('./brands/product');
  subProduct = require('./brands/subproduct');
} else {
  utilsProducts = svs.components.lbUtils.sportProducts;
  tipsenShared = svs.components.sport.tipsenShared;
  baseBrandModel = svs.components.lbUtils.brandMapping.brands.base;
  product = svs.components.lbUtils.brandMapping.brands.product;
  subProduct = svs.components.lbUtils.brandMapping.brands.subproduct;
}

const {
  productIds
} = utilsProducts;
const {
  ClientSubProductType
} = tipsenShared;
const {
  bomben,
  challenge,
  europatipset,
  fulltraff,
  maltipset,
  matchen,
  powerplay,
  racing,
  stryktipset,
  topptipsetExtra,
  topptipsetStryk,
  topptipset,
  oddset,
  poker,
  bingo,
  casino,
  liveCasino
} = product;
const {
  emTipsetEuropa,
  emTipset,
  tipsSm,
  tipsEm,
  vmTipsetEuropa,
  vmTipset
} = subProduct;

const getProductModel = productId => {
  switch (productId) {
    case productIds.STRYKTIPSET:
      return _objectSpread(_objectSpread({}, stryktipset), {}, {
        unknownProduct: false
      });
    case productIds.EUROPATIPSET:
      return _objectSpread(_objectSpread({}, europatipset), {}, {
        unknownProduct: false
      });
    case productIds.T8:
      return _objectSpread(_objectSpread({}, topptipset), {}, {
        unknownProduct: false
      });
    case productIds.T8EXTRA:
      return _objectSpread(_objectSpread({}, topptipsetExtra), {}, {
        unknownProduct: false
      });
    case productIds.T8STRYK:
      return _objectSpread(_objectSpread({}, topptipsetStryk), {}, {
        unknownProduct: false
      });
    case productIds.POWERPLAY:
      return _objectSpread(_objectSpread({}, powerplay), {}, {
        unknownProduct: false
      });
    case productIds.RACING:
      return _objectSpread(_objectSpread({}, racing), {}, {
        unknownProduct: false
      });
    case productIds.BOMBEN:
      return _objectSpread(_objectSpread({}, bomben), {}, {
        unknownProduct: false
      });
    case productIds.CHALLENGE:
      return _objectSpread(_objectSpread({}, challenge), {}, {
        unknownProduct: false
      });
    case productIds.MALTIPSET:
      return _objectSpread(_objectSpread({}, maltipset), {}, {
        unknownProduct: false
      });
    case productIds.MATCHEN:
      return _objectSpread(_objectSpread({}, matchen), {}, {
        unknownProduct: false
      });
    case productIds.FULLTRAFF:
      return _objectSpread(_objectSpread({}, fulltraff), {}, {
        unknownProduct: false
      });
    case productIds.ODDSET:
      return _objectSpread(_objectSpread({}, oddset), {}, {
        unknownProduct: false
      });
    case productIds.POKER:
      return _objectSpread(_objectSpread({}, poker), {}, {
        unknownProduct: false
      });
    case productIds.BINGO_M:
      return _objectSpread(_objectSpread({}, bingo), {}, {
        unknownProduct: false
      });
    case productIds.CASINO:
      return _objectSpread(_objectSpread({}, casino), {}, {
        unknownProduct: false
      });
    case productIds.LIVE_CASINO:
      return _objectSpread(_objectSpread({}, liveCasino), {}, {
        unknownProduct: false
      });
    default:
      return baseBrandModel;
  }
};
const getSubProductModel = subProductType => {
  switch (subProductType) {
    case ClientSubProductType.EM_TIPSET:
      return [emTipset];
    case ClientSubProductType.VM_TIPSET:
      return [vmTipset];
    case ClientSubProductType.EM_EUROPA:
      return [emTipset, emTipsetEuropa];
    case ClientSubProductType.VM_EUROPA:
      return [vmTipset, vmTipsetEuropa];
    case ClientSubProductType.TIPS_SM:
      return [tipsSm];
    case ClientSubProductType.TIPS_EM:
      return [tipsEm];
    case ClientSubProductType.NONE:
      return [];
    default:
      return [];
  }
};
const getCompiledBrand = (productId, subProductType) => {
  const productBrandModel = getProductModel(productId);
  const {
      brand: pBrand
    } = productBrandModel,
    pModel = _objectWithoutProperties(productBrandModel, _excluded);
  const brands = [pBrand];
  let compiledBrand = _objectSpread(_objectSpread({}, baseBrandModel), pModel);
  let brandName = pBrand;
  const subProductBrandModels = getSubProductModel(subProductType);
  subProductBrandModels.forEach(_ref => {
    let {
        brand: subPBrand
      } = _ref,
      subPModel = _objectWithoutProperties(_ref, _excluded2);
    brands.push(subPBrand);
    compiledBrand = _objectSpread(_objectSpread({}, compiledBrand), subPModel);
    brandName = subPBrand || brandName;
  });
  return _objectSpread(_objectSpread({}, compiledBrand), {}, {
    brand: brands,
    brandName
  });
};
const toExport = getCompiledBrand;

if (svs.isServer) {
  module.exports = toExport;
} else {
  setGlobal('svs.components.lbUtils.compileBrand.getCompiledBrand', toExport);
}


 })(window);